<template>
  <v-card>
    <v-subheader>{{ label }}</v-subheader>
    <v-card-text>
      <v-slider v-model="myValue" :min="min" :max="max" thumb-label>
        <template v-if="range.minLabel" v-slot:prepend>
          <div style="white-space:nowrap;margin-top:4px;">{{ range.minLabel }}</div>
        </template>
        <template v-if="range.maxLabel" v-slot:append>
          <div style="white-space:nowrap;margin-top:4px;">{{ range.maxLabel }}</div>
        </template>
      </v-slider>
    </v-card-text>
  </v-card>
</template>
<style>
.v-application.theme--dark .v-slider__thumb-label-container .v-slider__thumb-label.primary {
  color: #1E1E1e;
}
</style>
<script>
import { ref, watch } from '@vue/composition-api'
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    label: String,
    shortLabel: {
      type: String,
      default: 'Select Value'
    },
    range: {
      type: Object
    },
    min: Number,
    max: Number
  },
  setup (props, { emit }) {
    const myValue = ref('')

    watch(() => props.value, (val) => {
      myValue.value = val
    })

    watch(myValue, (val) => {
      emit('input', val)
    })

    return {
      myValue
    }
  }
}
</script>
